const Terms = () => {
  return (
    <>
      <div className="m-5">
        <h1 style={{ marginLeft: 80, marginRight: 80, textAlign: "center" }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            <span style={{ fontSize: "23pt" }}>
              <span style={{ color: "#0e101a" }}>Terms and Conditions&nbsp;</span>
            </span>
          </span>
        </h1>
        <h1 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Note</span>
            </span>
          </span>
        </h1>
        <p style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 14 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                The terms and conditions are subject to dynamic changes and
                modifications at any point in time without prior notification. It is
                incumbent upon you, as a user, to periodically review these terms to
                ensure familiarity with their most current iteration.
              </span>
            </span>
          </span>
        </p>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h3 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>General Provisions</span>
            </span>
          </span>
        </h3>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  The domain https://eximfiles.io/, along with any associated domains
                  belonging to subsidiary entities, holding companies, or affiliates,
                  is the proprietary digital property of{" "}
                  <strong>Dreamfuel Technologies Private Limited</strong> (hereafter
                  referred to as "Eximfiles"). This corporation is duly registered in
                  accordance with the legal stipulations of India, with its registered
                  office located at{" "}
                  <strong>
                    AshaYog, Unit No. 104, Vijaya Nagar Colony, 2104 (behind S. P.
                    College), Sadashiv Peth, Sane Guruji Marg, Pune - 411030, India.
                  </strong>
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Within the context of these Terms of Use, the terms "You," "Your,"
                  or "User" shall refer to any natural or legal entity who engages
                  with our website, either through registration or simple usage. "We,"
                  "Us," and "Our" shall collectively refer to Dreamfuel Technologies
                  Private Limited and its legal successors, assignees, or affiliates.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  The engagement with our website and the services offered therein are
                  regulated and governed by these Terms of Use, inclusive of all
                  related policies, which are herein incorporated by reference. If You
                  transact on the Website, You shall be subject to the policies that
                  are applicable to the website for such transaction. Your usage of
                  the website forms a legally binding agreement with Eximfiles,
                  encompassing these Terms of Use and all incorporated policies.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  This document is generated and validated as an electronic record in
                  the context of the Information Technology Act, 2000, and its
                  subsequent amendments, along with the provisions related to
                  electronic records in various statutes as amended by said Act. This
                  electronic record is published in adherence to the mandates of Rule
                  3 (1) of the Information Technology (Intermediaries Guidelines)
                  Rules, 2011, which necessitate the publication of rules,
                  regulations, privacy policies, and Terms of Use for access or
                  utilization of the website.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  We reserve the unilateral right to amend, modify, add, or remove
                  segments of these Terms of Use at any given time without the
                  requirement of prior written notice to You. It remains your
                  responsibility to review these Terms of Use for updates and changes
                  periodically. Your continued engagement with the website after the
                  implementation of any changes will be construed as your acceptance
                  and agreement to these revisions. As long as you adhere to these
                  Terms of Use, we confer upon you a personal, non-exclusive,
                  non-transferable, limited privilege to access and use the website.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Membership Eligibility</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Access to and use of the website is exclusively available to
                  individuals who can form legally binding contracts under the Indian
                  Contract Act, 1872.{" "}
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Individuals who are deemed "incompetent to contract" under the same
                  Act, including minors (below 18 years of age), un-discharged
                  insolvents, and others, are not eligible to use or transact on the
                  website.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Privacy Policy</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Your provision of Personal Information in the course of using our
                  services or the website is governed by our Privacy Policy, which is
                  accessible at
                </span>
                <a href="http://www.eximfiles.io/" style={{ textDecoration: "none" }}>
                  <span style={{ color: "#4a6ee0" }}>
                    <u> </u>
                  </span>
                </a>
                <span style={{ color: "#0e101a" }}>https://eximfiles.io/.</span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                Account and Registration Obligations
              </span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  During the process of registration, the information you provide is
                  crucial for the effective delivery of content, service, and account
                  management. You bear sole responsibility for safeguarding the
                  confidentiality of your account details, username, and password and
                  for all activities that occur under your account.{" "}
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You warrant that your account information will be accurate and
                  up-to-date at all times. Should any information be deemed untrue,
                  inaccurate, outdated, or incomplete, or should we have reasonable
                  grounds to suspect such, we retain the right to indefinitely
                  suspend, terminate, or block your access to the website and your
                  membership therein.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Communications</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Your use of the website or engagement in communication with us via
                  emails, data submissions, or other forms of communication, implies
                  your consent to communicate with us through electronic records. You
                  acknowledge and agree to receive communications from us periodically
                  and as required via electronic means or other methods. Additionally,
                  you grant Eximfiles the right to contact you on your registered
                  mobile number for verification purposes or to inform you about our
                  products, regardless of your registration on any Do Not Call
                  registry.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Term</span>
            </span>
          </span>
        </h2>
        <p style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 14 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                Unless specified otherwise within the provisions of the privacy
                policy, your profile and registration on the Platform remain active
                and valid for a duration of 5 (five) years from the date of
                registration.
              </span>
            </span>
          </span>
        </p>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                Transaction and Communication Platform
              </span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Our website serves as a platform enabling users to interact with
                  Eximfiles for various transactions, including availing of financial
                  services and facilities. To access these services, you are required
                  to register and provide personal and financial information. You
                  consent to Eximfiles collecting, authenticating, and verifying this
                  information as necessary for the provision of services.{" "}
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You acknowledge that Eximfiles, at its sole discretion, may assess
                  and determine your eligibility for these services based on the
                  provided information. You are solely responsible for all activities
                  occurring under your account while availing of these services and
                  for maintaining the confidentiality of your account. Eximfiles
                  reserves the right to approve or deny your registration for services
                  at its sole discretion.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Intellectual Property Rights</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Eximfiles owns and controls all content on this site, including but
                  not limited to images, illustrations, trademarks, copyrights, and
                  other intellectual property. All intellectual property related to
                  the services, content, and technology on the website is exclusively
                  owned by Eximfiles. Nothing in these Terms of Use or our privacy
                  policy should be construed as granting any license or right to use
                  any intellectual property on this website. The material on this
                  website is intended for personal, non-commercial use only.
                  Unauthorized reproduction, distribution, or use of any material from
                  this website is strictly prohibited.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  By agreeing to these Terms of Use, you acknowledge and consent to
                  the stipulations outlined herein. Your engagement with the website
                  signifies your acceptance of these terms, and you are advised to
                  peruse them meticulously prior to proceeding further.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Indemnity Clause</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You agree to indemnify, defend, and hold harmless Eximfiles, its
                  owners, licensees, affiliates, subsidiaries, group companies (as
                  applicable), and their respective officers, directors, agents, and
                  employees from any claims, demands, actions, costs (including
                  attorney's fees), or penalties incurred due to your breach of these
                  Terms of Use, privacy policy, or any other policies, or due to your
                  violation of any laws, rules, regulations, or infringement of
                  third-party rights.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You acknowledge that you are solely responsible for the
                  functionality and compatibility of your electronic devices and
                  internet connection used to access the Platform. The Platform's
                  performance on your device depends on the availability of necessary
                  hardware, software specifications, internet connectivity, and other
                  required features.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You agree to indemnify and hold Eximfiles, its affiliates,
                  directors, officers, employees, agents, and representatives harmless
                  against any losses, liabilities, costs, expenses, damages, including
                  legal fees, arising from your unauthorized use of Eximfiles or any
                  third-party intellectual property accessed through our services.
                  Legal actions may be initiated against you for such unauthorized
                  use.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Warranties</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Eximfiles makes no warranties or representations, whether express or
                  implied in law or residual, other than to the extent specifically
                  contained herein, including any warranties regarding the content,
                  services or technology provided by it and disclaims all warranties,
                  express or implied, in connection with the content, services or
                  technology to the fullest extent as permitted by law.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Disclaimer</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  The website and all content and services provided to you are on an
                  "as is" and "as available" basis without any warranties, either
                  express or implied, unless otherwise specified in writing. Eximfiles
                  does not guarantee that the website will be constantly available, or
                  available at all; that the content is up-to-date, complete,
                  accurate, or applicable; that the website will meet your
                  requirements or be uninterrupted, timely, secure, or error-free;
                  that results obtained from the website will be accurate or reliable;
                  or that any service or information obtained by you through the
                  website will meet your expectations. Eximfiles does not warrant that
                  the website, its servers, or electronic communications are free of
                  viruses or other harmful components.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Limitation of Liability</span>
            </span>
          </span>
        </h2>
        <ul>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Eximfiles shall not be liable for any special, incidental, indirect,
                  or consequential damages in connection with these Terms of Use, even
                  if informed in advance of such potential damages. Eximfiles is not
                  liable for any injuries, losses, or damages arising from the use of
                  the website, or any failure, delay, or malfunction of the website.
                  This limitation also applies to damages from viruses or other
                  harmful components in your computer equipment due to your access to,
                  or downloading from, the website.
                </span>
              </span>
            </span>
          </li>
          <li style={{ marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Eximfiles is not responsible for the availability or content of
                  third-party websites linked to our website, nor for any content,
                  advertising, products, or materials on such third-party websites.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Termination</span>
            </span>
          </span>
        </h2>
        <p style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 14 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                Violation of these Terms of Use may result in termination of your
                access to the Platform. We may terminate your access for any reason
                without prior notice. This is without prejudice to any other legal
                remedies available to us. We are not liable for any consequences of
                such termination or discontinuance.
              </span>
            </span>
          </span>
        </p>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <p style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Platform use</span>
            </span>
          </span>
        </p>
        <p style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 14 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                Your use of the Platform is governed by the following principles:
              </span>
            </span>
          </span>
        </p>
        <ul>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You must not host, display, upload, or share any information that
                  violates another's rights, is harmful, obscene, defamatory, or
                  illegal in any manner.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You shall not engage in any unauthorized or unlawful activities on
                  the website, including hacking, data mining, or other illegitimate
                  means of access.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You are prohibited from probing, scanning, or testing the website's
                  vulnerability, and from breaching security or authentication
                  measures.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You must not make any statements or engage in actions that tarnish
                  the image or reputation of Eximfiles.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You agree not to interfere with the proper working of the website or
                  any transactions conducted on it
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You agree not to interfere with the proper working of the website or
                  any transactions conducted on it.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You shall not use the website for unlawful purposes or in violation
                  of these Terms of Use.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You must comply with all applicable laws and regulations, including
                  those governing online transactions.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  You grant us a non-exclusive, worldwide, perpetual, irrevocable,
                  royalty-free right to use the information you provide in accordance
                  with these Terms of Use and our privacy policy.
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: "disc", marginLeft: 80, marginRight: 80 }}>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <span style={{ color: "#0e101a" }}>
                  Eximfiles reserves the right, but not the obligation, to monitor
                  materials posted on the website.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p style={{ marginLeft: 80, marginRight: 80 }}>&nbsp;</p>
        <h2 style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>Applicable Law&nbsp;</span>
            </span>
          </span>
        </h2>
        <p style={{ marginLeft: 80, marginRight: 80 }}>
          <span style={{ fontSize: 14 }}>
            <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
              <span style={{ color: "#0e101a" }}>
                These Terms of Use shall be governed and construed in accordance with
                the laws of India.
              </span>
            </span>
          </span>
        </p>
      </div>
    </>
  )
}

export default Terms;