import './index.css';
import { TabProperties } from '../../data';
import Tabs from './Tabs';
import TabContent from './TabContent';
import { useState } from 'react';
import MobileViewItem from './MobileViewItem';
import SeeYourself from '../../assets/icons/SeeYourself.svg';
import HowDoesItWork from '../../assets/icons/HowDoesItWork.svg';
import { PopupModal } from 'react-calendly';
import mixpanel from 'mixpanel-browser';
mixpanel.init('2c90bf9ed56b3cc33540e2ec9969edf4');

const HowWorks = () => {
  const [tabValue, setTabValue] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const element = document.getElementById('root');
  const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '00a2ff',
    textColor: '4d5055',
  };

  return (
    <>
      {openPopup && (
        <PopupModal
          url="https://calendly.com/eximfiles/product-demo-eximfiles?primary_color=6867f0"
          rootElement={element}
          open={openPopup}
          pageSettings={pageSettings}
          onModalClose={() => setOpenPopup(false)}
        />
      )}
      <div className="how-works-container">
        <div className="how-title">
          <img src={HowDoesItWork} alt="" />
        </div>
        <div className="globe-platform">
          With just a few shipment details, we’ll automatically generate all necessary documents for your shipment, simplifying the process for you.
        </div>
        <img src={SeeYourself} alt="" className="see-img" />
        <div className="d-none d-md-block">
          <div className="tab-section">
            <Tabs
              properties={TabProperties}
              tabValue={tabValue}
              setTabValue={(value) => setTabValue(value)}
            />
            <TabContent
              tabValue={tabValue}
              property={TabProperties[tabValue - 1]}
            />
          </div>
        </div>
        <div className="bottom-button">
          <button 
            className="book-demo-btn"
            onClick={() => {
              if(window.location.hostname === 'eximfiles.io') {
                mixpanel.track('BOOK_A_DEMO', {});
              }
              setOpenPopup(true);
            }}
          >
            Book a demo
          </button>
        </div>
        <div className="d-md-none d-sm-block mt-4">
          {TabProperties.map((property, index) => {
            return (
              <div key={index}>
                <MobileViewItem
                  index={index + 1}
                  heading={property.title}
                  property={property}
                />
              </div>
            )
          })}
          <div className="mob-bottom-button">
            <button 
              className="book-demo-btn"
              onClick={() => {
                if(window.location.hostname === 'eximfiles.io') {
                  mixpanel.track('BOOK_A_DEMO', {});
                }
                setOpenPopup(true);
              }}
            >
              Book a demo
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWorks;
