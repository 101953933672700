import './index.css';
import Create from '../../assets/icons/Create.svg';
import Manage from '../../assets/icons/Manage.svg';
import Collaborate from '../../assets/icons/Collaborate.svg';
import Analyze from '../../assets/icons/Analyze.svg';

const TabContent = (props) => {
  return (
    <>
      <div className="tab-container">
        <div className="content-section">
          <h2 className="tab-header">{ props.property.header }</h2>
          <div className="tab-info">{ props.property.info }</div>
        </div>
        <div className="thumbnail">
          {props.tabValue === 1 && <img src={Create} className="tab-img" /> }
          {props.tabValue === 2 && <img src={Manage} className="tab-img" /> }
          {props.tabValue === 3 && <img src={Collaborate} className="tab-img" /> }
          {props.tabValue === 4 && <img src={Analyze} className="tab-img" /> }
        </div>
      </div>
    </>
  );
};

export default TabContent;