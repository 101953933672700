import { Logo, MobLogo } from '../../iconComponents';
import './index.css';
import AllIn from '../../assets/icons/AllIn.svg';
import mail from '../../assets/icons/mail.svg';
import call from '../../assets/icons/call.svg';
import location from '../../assets/icons/location.svg';
import SvgFooterLine from '../../iconComponents/FooterLine';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const router = useNavigate();
  return (
    <div className="footer-background">
      <div className="footer-container">
        <div className="top-side">
          <Logo width={154} height={30} />
          <div className="backed-section">
            <div className="backed-by">Backed By</div>
            <a href="https://allincapital.vc/" target={'_blank'}>
              <img src={AllIn} alt="" className="allin-img" />
            </a>
          </div>
        </div>
        <div className="middle-side mt-6">
          <div className="contact-us-section">
            <div className="contact-us">Contact Us</div>
            <div className="email mt-2"><img src={mail} alt="" />&nbsp;&nbsp;hello@eximfiles.io</div>
            {/* <div className="phone mt-2"><img src={call} alt="" />&nbsp;&nbsp;+91 9875750101 (India)</div> */}
          </div>
          <div className="address-section">
            <div className="company-section">
              <div className="country"><img src={location} alt="" />&nbsp;&nbsp;USA</div>
              <div className="company-name">Eximtech Inc.</div>
              <div className="address">16192 Coastal Highway Lewes, DE 19958</div>
            </div>
            <div className="company-section">
              <div className="country"><img src={location} className="mb-2" alt="" />&nbsp;&nbsp;India</div>
              <div className="company-name">Dreamfuel Technologies Pvt Ltd</div>
              <div className="address">AshaYog, Unit No. 104, Vijaya Nagar Colony, 2104 (behind S. P. College), Sadashiv Peth, Sane Guruji Marg, Pune - 411030 </div>
            </div>
            <div className="policy-flex">
              <div className="link" onClick={() => window.open('/privacy-policy', '_blank')}>Privacy Policy</div>
              <div className="divider d-none d-md-block">|</div>
              <div className="link" onClick={() => window.open('/terms-conditions', '_blank')}>Terms & Conditions</div>
            </div>
          </div>
        </div>
        <div className="footer-line">
          <SvgFooterLine width={'50%'} height={1} />
        </div>
        <div className="footer-bar">
          Eximtech Inc. ©2023 All Rights Reserved.
        </div>
      </div>  
    </div>
  )
}

export default Footer;