import './index.css';
import Accordion from './Accordion';
import { useState } from 'react';

const MobileViewItem = (props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const setAccordionState = (value) => {
    setIsAccordionOpen(value);
  };

  return (
    <>
      <div className="accordion-container">
        <Accordion
          heading={props.heading}
          setAccordionState={(value) => setAccordionState(value)}
        />
        {isAccordionOpen && (
          <div 
            className="accord-content" 
            style={{ 
              borderTopStyle: isAccordionOpen ? 'none' : '', 
              borderRadius: isAccordionOpen ? '0 0 8px 8px' : '8px' 
            }}
          >
            {props.index === 1 && <div className="thumbnail-img create-img"></div> }
            {props.index === 2 && <div className="thumbnail-img manage-img"></div> }
            {props.index === 3 && <div className="thumbnail-img collab-img"></div> }
            {props.index === 4 && <div className="thumbnail-img analyze-img"></div> }
            <div className="content-section p-3">
              <div className="tab-header">{ props.property.header }</div>
              <div className="tab-info">{ props.property.info }</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MobileViewItem;