/* eslint-disable */
import './index.css';
import Checkbox from '../../assets/icons/checkbox.svg';
import { useState } from 'react';
import mixpanel from 'mixpanel-browser';
mixpanel.init('2c90bf9ed56b3cc33540e2ec9969edf4');

const EmailAccess = (props) => {
  const [showInput, setShowInput] = useState(true);
  const goToEmailSection = () => {
    if(window.location.hostname === 'eximfiles.io') {
      mixpanel.track('GET_ACCESS', {});
    }
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSe8I7vy18XxEaZdgGxV0oady9TxUu6IXgrLCpAlyJPegB6SaA/viewform?usp=sf_link', '_blank')
  };

  return (
    <>
      <div className="email-container">
        <div className="img-back text-center">
          {showInput ? (
            <div>
              <div className="being-early">Being early is rewarding.</div>
              <div className="get-early-access">Get early access.</div>
              <button
                className="submit-btn" 
                onClick={goToEmailSection}
              >Get Access</button>
              <div className="bottom-text">
                Join eximfiles now and simplify your global trade management and collaboration like a pro!
              </div>
            </div>
          ) : (
            <div>
              <img src={Checkbox} className="checkbox-img" alt="lazy" />
              <div className="awesome">Awesome !!</div>
              <div className="check-email">Please check your email for additional info.</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailAccess;