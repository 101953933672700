import './index.css';
import Solution from '../../assets/icons/Solution.svg';
import Challenge from '../../assets/icons/Challenge.svg';
import LeftCurve from '../../assets/icons/LeftCurve.svg';
import RightCurve from '../../assets/icons/RightCurve.svg';
import YesSmile from '../../assets/icons/YesSmile.svg';
import NoSmile from '../../assets/icons/NoSmile.svg';
import YesSmile1 from '../../assets/icons/YesSmile1.svg';
import NoSmile1 from '../../assets/icons/NoSmile1.svg';
import KeyChallenges from '../../iconComponents/KeyChallenges';
import { useState } from 'react';

const Hello = () => {
  const [checked, setChecked] = useState(true);

  return (
    <>
      <div className="hello-container">
        <div className="key-challenges">
          <KeyChallenges width={883} height={64} />
        </div>
        <div className="no-more">Global trade documentation is now 90% easier, faster and error-free.</div>
        {checked && ( <img src={Solution} alt="" className="challenge-img" /> )}
        {!checked && ( <img src={Challenge} alt="" className="challenge-img" /> )}
        <div className="toggle-container">
          {checked ? (
            <div className="d-flex">
              <div className="left-section-1">
                <img src={YesSmile} className="smile-img" alt="" />
                <div className="with-eximfiles-1">With Eximfiles</div>
              </div>
              <div className="right-section-1" onClick={() => setChecked(false)}>
                <div className="without-eximfiles-1">Without Eximfiles</div>
                <img src={NoSmile} className="smile-img" alt="" />
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <div className="left-section-2" onClick={() => setChecked(true)}>
                <img src={YesSmile1} className="smile-img" alt="" />
                <div className="with-eximfiles-2">With Eximfiles</div>
              </div>
              <div className="right-section-2">
                <div className="without-eximfiles-2">Without Eximfiles</div>
                <img src={NoSmile1} className="smile-img" alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hello;