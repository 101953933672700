import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './landing';
import Privacy from './privacy';
import Refund from './refund';
import Terms from './terms';
import NotFound from './NotFound';
import Sitemap from './sitemap';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/refund-policy" element={<Refund />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/sitemap.xml" element={<Sitemap />} />
          {/* The catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;