import './index.css';
import Consignment from '../../assets/icons/Consignment.svg';
import Notes from '../../assets/icons/Notes.svg';
import ProductCatalog from '../../assets/icons/ProductCatalog.svg';
import KeyHighlights from '../../assets/icons/KeyHighlights.svg';

const OtherFeatures = () => {
  return (
    <>
      <div className="other-features-container">
        <div className="other-gradient-line"></div>
        <div className="other-features-title">
          <img src={KeyHighlights} alt="" />
        </div>
        <div className="feature-list">
          <div className="feature-card">
            <img src={ProductCatalog} alt="" className="feature-img" />
            <div className="feature-title">Product Catalog</div>
            <div className="feature-desc">
              Impress customers with speedy catalog creation & delivery. Easily curate & design catalogs on the spot, & send them directly to buyers.
            </div>
          </div>
          <div className="feature-card">
            <img src={Consignment} alt="" className="feature-img" />
            <div className="feature-title">Consignment Timeline</div>
            <div className="feature-desc">
              Streamline the handling of your consignments and stay up-to-date with all related updates through efficient notification systems.   
            </div>
          </div>
          <div className="feature-card">
            <img src={Notes} alt="" className="feature-img-3" />
            <div className="feature-title">Notes</div>
            <div className="feature-desc">
              This note feature enables error-free form filling by storing your essential information in one convenient location, accessible anytime and anywhere.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherFeatures;